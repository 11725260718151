<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Currency</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <currency-grid :key="gridReload" :deleteClick="deleteClick" :editClick="editClick"/>
                <currency-form ref="currencyForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import currencyService from '../Currency/Script/CurrencyService.js';
import currencyGrid from '../Currency/Grid/CurrencyGrid.vue';
import currencyForm from '../Currency/Component/CurrencyForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Currency',
    components: {
        'currency-grid': currencyGrid,
        'currency-form': currencyForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Currency');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.currencyForm.addClick();
        },
        editClick(currencyData, view){
            this.$refs.currencyForm.editClick(currencyData, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                const variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    currencyService.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>