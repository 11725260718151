<template>
    <div>
        <input hidden type="text" class="form-control" id="CurrencyId" v-model="CurrencyId">
        <div class="modal fade" id="currencyModal" tabindex="-1" aria-labelledby="currencyModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="currencyModalLabel" class="font-weight-bold">{{ModalTitle}}{{CurrencyId}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>

                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Kode Currency</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="CurrencyCode" v-model="CurrencyCode" class="font-weight-bold" />
                                    <label id="errorCurrencyCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama Currency</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="CurrencyName" v-model="CurrencyName" class="font-weight-bold" />
                                    <label id="errorCurrencyName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Simbol Currency</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="CurrencySymbol" v-model="CurrencySymbol" class="font-weight-bold" />
                                    <label id="errorCurrencySymbol" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                           
                            <br>
                            <label id="errorCurrencyGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import currencyService from '../Script/CurrencyService.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'CurrencyForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            CurrencyId:'',
            CurrencyCode: '',
            CurrencyName: '',
            CurrencySymbol: '',
           
            Error: 0,
            SaveType: '',
        }
    },
    // mounted(){

    // },
    methods: {
        addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Currency'       
            this.CurrencyId = '';
            this.CurrencyCode = '';
            this.CurrencyName = '';
            this.CurrencySymbol = '';

            this.SaveType = 'Add';
            window.$('#currencyModal').modal('show');
        },
        editClick(currencyData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Currency: '       
            this.CurrencyId = currencyData.id;
            this.CurrencyCode = currencyData.currency_code;
            this.CurrencyName = currencyData.currency_name;
            this.CurrencySymbol = currencyData.currency_symbol;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Currency: ';
            }

            window.$('#currencyModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.CurrencyCode == ''){
                this.errorShow('errorCurrencyCode');
            }
            if(this.CurrencyName == ''){
                this.errorShow('errorCurrencyName');
            }
            if(this.CurrencySymbol == ''){
                this.errorShow('errorCurrencySymbol');
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                
                const currencyData = {
                    currency_code: this.CurrencyCode,
                    currency_name: this.CurrencyName,
                    currency_symbol: this.CurrencySymbol
                }; 
                this.$loading(true);
                
                // Add
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : currencyData
                    }

                    currencyService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#currencyModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id : this.CurrencyId,
                        data : currencyData
                    }

                    currencyService.updateQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#currencyModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
